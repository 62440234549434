










































































































import { Component, Vue } from "vue-property-decorator";
import { mapMutations, mapActions, mapGetters } from "vuex";

import * as types from "@/store/types";

@Component({
    computed: {
        ...mapGetters({
            userName: types.AUTH_USERNAME
        })
    },
    methods: {
        ...mapMutations({
            setDrawer: types.MUTATE_APP_DRAWER
        }),
        ...mapActions({
            authLogout: types.AUTH_LOGOUT
        })
    }
})
export default class Drawer extends Vue {
    setDrawer!: (state: boolean) => void;
    authLogout!: () => void;
    userName!: string;

    version = process.env.VUE_APP_VERSION || "nover";

    items = [
        {
            title: "documentation",
            icon: "mdi-text-box-multiple-outline",
            to: "/documentation"
        },
        {
            title: "environmentalFlow",
            icon: "mdi-waves",
            to: "/"
        },
        {
            title: "dams",
            icon: "mdi-waterfall",
            to: "/dams"
        },
        {
            title: "piezometry",
            icon: "mdi-sign-pole",
            to: "/piezometry"
        },
        {
            title: "marmenor",
            icon: "mdi-sign-pole",
            to: "/marmenor"
        },
        {
            title: "qualitySaica",
            icon: "mdi-check-outline",
            to: "/qualitysaica"
        },
        {
            title: "waterMeter",
            icon: "mdi-water-circle",
            to: "/watermeter"
        },
        {
            title: "meteo",
            icon: "mdi-weather-cloudy",
            to: "/meteo"
        },
        {
            title: "droughtIndices",
            icon: "mdi-water-alert",
            to: "/droughtindices"
        },
        {
            title: "l1",
            icon: "mdi-weather-partly-snowy-rainy",
            to: "/l1"
        },
        {
            title: "l2",
            icon: "mdi-weather-cloudy-arrow-right",
            to: "/l2"
        },
        {
            title: "l3",
            icon: "mdi-spa",
            to: "/l3"
        }
    ];

    itemsWithChildrens = [
        {
            title: "l4",
            icon: "mdi-calendar-range",
            to: "/l4",
            childrens: [
                {
                    title: "dashboard",
                    icon: "mdi-monitor-dashboard",
                    to: "/l4/dashboard"
                },
                {
                    title: "hydroScheme",
                    icon: "mdi-map",
                    to: "/l4/hydroscheme"
                },
                {
                    title: "co2",
                    icon: "mdi-molecule-co2",
                    to: "/l4/co2"
                },
                {
                    title: "hydroEconomic",
                    icon: "mdi-finance",
                    to: "/l4/hydroeconomic"
                }
            ]
        },
        {
            title: "l5",
            icon: "mdi-wrench-clock",
            to: "/l5",
            childrens: [
                {
                    title: "dashboard",
                    icon: "mdi-monitor-dashboard",
                    to: "/l5/dashboard"
                },
                {
                    title: "hydroScheme",
                    icon: "mdi-map",
                    to: "/l5/hydroscheme"
                },
                {
                    title: "co2",
                    icon: "mdi-molecule-co2",
                    to: "/l5/co2"
                },
                {
                    title: "hydroEconomic",
                    icon: "mdi-finance",
                    to: "/l5/hydroeconomic"
                }
            ]
        }
    ];

    /**
     * Computed
     */

    get drawer() {
        return this.$store.state.app.drawer;
    }

    set drawer(val) {
        this.setDrawer(val);
    }

    get sections() {
        return this.items;
    }

    get sectionsWithChildrens() {
        return this.itemsWithChildrens;
    }

    /**
     * Methods
     */
    logout() {
        this.authLogout();
    }
}
